import React, { Component } from 'react';
import {  Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';

import "./../css/widget/SideBarAdCard.css";


import { AdsService } from "./../services/ads";
class SideBarAdCard extends Component {
    
    constructor(props) {
        super(props); 
        this.state = {
            ads: [],
        };   
    }    
    componentDidMount() {
      this.fetchSidebarAds();
    } 
    fetchSidebarAds = async () => {
      const ads = await AdsService.fetchSidebarAds();
      this.setState({ ads }); 
    };
    render(){
        return (
            <>
            {
                this.state.ads.map((item)=>{
                    return (
                        <Card className="card-block" key={"card-block-sidebar"+item.id}>
                            <Link to={item.link}> 
                                <Card.Img src={item.image} alt={item.title}/>
                            </Link>
                        </Card>
                    );
                })
            }
            </>
        )
    }
}

export default SideBarAdCard;