import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class BindingCheck extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
      value: [],  

    }; 
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return { value: state.value };
    }
    return null;
  }

  CheckboxChange = (e) => {
    const { value, checked } = e.target;

    this.setState((state) => {
      const newSelectedValues = checked
        ? [...state.value, value]  
        : state.value.filter((item) => item !== value); 

        if (this.state.onBindChange) {
          this.state.onBindChange(newSelectedValues);
        }

      return {
        value: newSelectedValues,
      };
    });
  };

  render() {
    const { title, name, error } = this.props;
    const { value, options } = this.state;  

    return (
      <div className="input-field">
        <Form.Group className="mb-3" controlId={name}>
          <Form.Label>{title}</Form.Label>
          <InputGroup hasValidation>
              {options && options.length > 0 && (
                options.map((option, index) => {
                 
                  if (option) {
                    return (
                      <Form.Check
                        key={index}
                        id={`${name}${option}`}
                        type="checkbox"
                        label={option}
                        name={name}
                        value={option}
                        checked={value.includes(option)} 
                        onChange={this.CheckboxChange}
                        isInvalid={error ? true : false}  
                      />
                    );
                  }
                  return null; 
                })
              )}
              
              {error && (
                <Form.Control.Feedback type="invalid">
                  {error} 
                </Form.Control.Feedback>
              )}
            </InputGroup>

        </Form.Group>
      </div>
    );
  }
}
export default BindingCheck;
