import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { dateFormat } from "../config/common";
import DetailCard from "../widgets/DetailCard";

import d1 from "./../images/d1.png";

import "./../css/widget/SearchCard.css";
class SimilarProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      active: props.active,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      active: props.active,
    };
  }
  render() {

    const related = [
      {
        id: 1,
        name: "Dismissal, Discharge, Termination of Service and Punishment",
        img: d1,      
        newPrice: "₹1,349",
        Author: "by L C Malhotra",
        description:
          "Starting the solids journey for your baby is an exciting journey but this can often feel like...",
      },
    
      {
        id: 2,
        name: "Dismissal, Discharge, Termination of Service and Punishment",
        img: d1,
      
        newPrice: "₹1,349",
        Author: "by L C Malhotra",
        description:
          "Starting the solids journey for your baby is an exciting journey but this can often feel like...",
      },
      {
        id: 3,
        name: "Dismissal, Discharge, Termination of Service and Punishment",
        img: d1,
       
        newPrice: "₹1,349",
        Author: "by L C Malhotra",
        description:
          "Starting the solids journey for your baby is an exciting journey but this can often feel like...",
      },
     
      
     
    ];

    return (
      <div>
        <div className="data">
          {related.map((item, i) => {
            return (
              <DetailCard
                key={i}
                id={item.id}
                name={item.name}
                img={item.img}
                Op={item.oldPrice}
                np={item.newPrice}
                Author={item.Author}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
export default SimilarProducts;
