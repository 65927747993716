import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";

import { dateFormat } from "./../config/common";

import "./../css/widget/NewsList.css";
import noimage from "./../images/dailytime-noimg.png";

import { NewsService } from "./../services/news";
// import OneBlockAdCard from "../widgets/OneBlockAdCard";

class NewsList extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      category: props.category,
      tag: props.tag,
      ads:props.ads,
      adm:props.adm,
      news: [],
      rightnews:null,
      leftnews:null,

    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      category: props.category,
      tag: props.tag,
    };
  }
  componentDidMount() {
    this.fetchPostByCategory();
  }
  fetchPostByCategory = async () => {
    const slug = this.state.category;
    let { news } = await NewsService.fetchPostByCategory(slug);
    if(news&&news.length>0){
        const leftnews =news[0];
        this.setState({ leftnews });
        if(news.length>1){
            const rightnews =news[1];
            this.setState({ rightnews });
        }
        // console.log(this.state.adm,this.state.ads)
        // if(this.state.ads&&this.state.adm==="onecol"){

        //   if(news.length>4){
        //       news = news.slice(4,-1);
        //       this.setState({ news });
        //   }
        // }else{
          if(news.length>2){
              news = news.slice(2,-1);
              this.setState({ news });
          }
        // }
    } 
  };
  render() {
    return (
      <>
        {/* {
          (this.state.ads&&this.state.ads.length>0&&this.state.adm==="onecol")?(<>
          <div className="col-md-6" > 
            <div className="row split-row row-two">
              <div className="col-md-12 block-col1">
                <OneBlockAdCard
                  ads={this.state.ads[0]}
                ></OneBlockAdCard>
              </div>
            </div>
          </div>
          </>):(<></>)
        } */}

        { this.state.leftnews ? (
          <div className="col-md-6 news-col-wrap news-col-wrap">
            <div className="row  split-row row-three">
              <div className="block-col1 col-md-12">
                <div className="news-wrap-block">
                  <span className="latest-txt">{this.state.tag}</span>
                  <Card className="latest-block">
                    <Card.Body>
                      <div className="latest-block">
                      <Link to={"/" + this.state.leftnews.slug}>
                        <div className="news-list-img">
                          {this.state.leftnews.categoryname.map((itm) => {
                            return itm.subcategory ? (
                              <span className="latest-name">
                                {itm.subcategory}
                              </span>
                            ) : (
                              <></>
                            );
                          })}
                          <div className="latest-img">
                            <img
                              src={
                                this.state.leftnews.image
                                  ? this.state.leftnews.image
                                  : noimage
                              }
                              alt={
                                this.state.leftnews.image_alt
                                  ? this.state.leftnews.image_alt
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="news-list-cnt">
                          <div className="news-list-top">
                            <span className="list-top-rgt">
                              {dateFormat(
                                this.state.leftnews.publish_date,
                                "M d, Y"
                              )}
                            </span>
                          </div>

                         
                            <h2>{this.state.leftnews.title}</h2>
                            <div className="content">
                              {this.state.leftnews.content.replace(
                                /<[^>]+>/g,
                                ""
                              )}
                            </div>
                         
                          <div className="news-list-bottom">
                            <span className="list-btm-lft">
                              <span className="viewcount">
                                {this.state.leftnews.views_count}
                              </span>
                              {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                            </span>
                            <span className="list-btm-rgt"> </span>
                          </div>
                        </div>
                        </Link>
                      </div>
                      {this.state.rightnews ? (
                        <div className="latest-block">
                           <Link to={"/" + this.state.rightnews.slug}>
                          <div className="news-list-img">
                            {this.state.rightnews.categoryname.map((itm) => {
                              return itm.subcategory ? (
                                <span className="latest-name">
                                  {itm.subcategory}
                                </span>
                              ) : (
                                <></>
                              );
                            })}
                            <div className="latest-img">
                              <img
                                src={
                                  this.state.rightnews.image
                                    ? this.state.rightnews.image
                                    : noimage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="news-list-cnt">
                            <div className="news-list-top">
                              {/* <span className="list-top-lft">
                                                                        The Times of India
                                                                        </span> */}
                              <span className="list-top-rgt">
                                {dateFormat(
                                  this.state.rightnews.publish_date,
                                  "M d, Y"
                                )}
                              </span>
                            </div>

                            
                              <h2>{this.state.rightnews.title}</h2>
                              <div className="content">
                                {this.state.leftnews.content.replace(
                                  /<[^>]+>/g,
                                  ""
                                )}
                              </div>
                            
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="viewcount">
                                  {this.state.rightnews.views_count}
                                </span>
                                {/* <span className="like">55</span>
                                                                        <span className="dislike"></span> */}
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {this.state.news && this.state.news.length > 1 ? (
            <div className="col-md-6 news-col-wrap news-col-slider">
              <div className="row split-row row-three">
                <div className=" col-md-12 block-col2  ">
                  <div className="latest-wrap latest-wrap-row  ">
                    <span className="latest-txt">
                        {this.state.tag}
                    </span>
                    <div className="news-slider2-wrap  " >
                      <Slider
                        className={
                            this.state.news.length >  2
                            ? "news-slider2"
                            : "news-slider2 no-slide"
                        }
                        infinite={false}
                        slidesToShow={1}
                        slidesToScroll={1}
                      >
                        {
                          this.state.news.filter((x, y) => {
                            return y % 2 === 0;
                          })
                          .map((item, y) => {
                            let nextslide=<></>
                            if(this.state.news[(y*2)+1]){
                                nextslide=(<div className="latest-block">
                                   <Link
                                    to={
                                      "/" +  this.state.news[(y*2)+1].slug
                                    }
                                  >
                                <div className="news-list-left">

                                {this.state.news[(y*2)+1].categoryname.map((itm) => {
                                            return itm.subcategory ? (
                                            <span className="latest-name">
                                                {itm.subcategory}
                                            </span>
                                            ) : (
                                            <></>
                                            );
                                        })}
                                       
                                  <div className="latest-img">
                                    <img
                                      src={
                                        this.state.news[(y*2)+1].image ? this.state.news[(y*2)+1].image : noimage
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="news-list-right">
                                  <div className="news-list-top">
                                    {/* <span className="list-top-lft">
                                                            The Times of India
                                                        </span> */}
                                    <span className="list-top-rgt">
                                      {dateFormat(
                                        this.state.news[(y*2)+1].publish_date,
                                        "M d, Y"
                                      )}
                                    </span>
                                  </div>
                                 
                                    <h2>{this.state.news[(y*2)+1].title}</h2>
                                    <div
                                      className="content" 
                                    >
                                        {
                                            this.state.news[(y*2)+1].content.replace(
                                                /<[^>]+>/g,
                                                ""
                                              )
                                        }
                                    </div>
                                 
                                  <div className="news-list-bottom">
                                    <span className="list-btm-lft">
                                      <span className="viewcount">
                                        {this.state.news[(y*2)+1].views_count}
                                      </span>
                                      {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                    </span>
                                    <span className="list-btm-rgt"> </span>
                                  </div>
                                </div>
                                </Link>
                              </div>)
                            }
                            return (
                              <Card
                                className="latest-block"
                                key={"latest-op-block" + item.id}
                              >
                                <Card.Body>
                                  <div className="latest-block">
                                  <Link
                                        to={
                                          "/" +  item.slug
                                        }
                                      >
                                    <div className="news-list-left">
                                    {item.categoryname.map((itm) => {
                                            return itm.subcategory ? (
                                            <span className="latest-name">
                                                {itm.subcategory}
                                            </span>
                                            ) : (
                                            <></>
                                            );
                                        })}
                                      
                                      <div className="latest-img">
                                        <img
                                          src={
                                            item.image ? item.image : noimage
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="news-list-right">
                                      <div className="news-list-top">
                                        {/* <span className="list-top-lft">
                                                                The Times of India
                                                            </span> */}
                                        <span className="list-top-rgt">
                                          {dateFormat(
                                            item.publish_date,
                                            "M d, Y"
                                          )}
                                        </span>
                                      </div>
                                     
                                        <h2>{item.title}</h2>
                                        <div
                                          className="content" 
                                        >

                                            {
                                                item.content.replace(
                                                    /<[^>]+>/g,
                                                    ""
                                                  )
                                            }
                                        </div>
                                      
                                      <div className="news-list-bottom">
                                        <span className="list-btm-lft">
                                          <span className="viewcount">
                                            {item.views_count}
                                          </span>
                                          {/* <span className="like">55</span>
                                                                <span className="dislike"></span> */}
                                        </span>
                                        <span className="list-btm-rgt"> </span>
                                      </div>
                                    </div>
                                    </Link>
                                  </div>
                                  {nextslide }
                                  {
                                    nextslide?(

                                <Link to={"/news/" + this.state.category} className="seemore">
                                {" "}
                                See More{" "}
                              </Link>
                                    ):(<></>)
                                  }
                                </Card.Body>
                              </Card>
                            );
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ):(<></>)}
      </>
    );
  }
}

export default NewsList;
