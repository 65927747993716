import React, { Component } from "react";
import { Card,  Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class FormCard extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
      return {
        ...props,
      };
  }
  render() {
    return (
      <Card >
        <Card.Body>
          <Card.Title>{this.state.title}</Card.Title>
          <Form action={this.state.action} method={this.state.method} onSubmit={this.state.handle} noValidate >
            {this.state.children}
          </Form>
   
        </Card.Body>
      </Card>
    );
  }
}
export default FormCard;
