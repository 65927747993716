import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
import Select from "react-select";
class FormDropdown extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
      return {
        ...props,
      };
  }
  render() {
    const optionValue=(this.state.value||[]).filter((v)=>{
      return (this.state.options||[]).find(option => option.value === v)?true:false;
    }) 
    return (        
        <div className="input-field">
          <Form.Group className="mb-3" controlId={this.state.id}>
              <Form.Label>{this.state.title}</Form.Label>
              <InputGroup hasValidation>
                <Select isInvalid={this.state.error?true:false} options={this.state.options||[]} name={this.state.name} value={Array.from(optionValue, (v,k) => (this.state.options||[]).find(option => option.value === v)??{label:v,value:v})} onChange={(e)=>{
                   
                  this.state.handle({
                    target:{
                      name:this.state.name,
                      value:Array.from(e, option => option.value)
                    }
                  })
                }} placeholder={this.state.placeholder} isMulti={this.state.multiple}  />
                  
                <Form.Control.Feedback type="invalid">
                    {this.state.error}
                </Form.Control.Feedback>
              </InputGroup>
          </Form.Group> 
        </div>
    );
  }
}
export default FormDropdown;
