import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { dateFormat } from "../config/common";
import CartCard from "../widgets/CartCard";
import Catalog from "../Catalog";
import d1 from "./../images/d1.png";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { PaymentService } from "../services/payment";

import "./../css/widget/CartComponent.css";
class CartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      active: props.active,
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      active: props.active,
    };
  }

  componentDidUpdate(){
    if (!this.state.isloaded && Catalog.isLoad) {
      if(!this.intervalId){
        this.intervalId = setInterval(this.refreshlist, 1000);
        this.initializeRazorpay();
      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.intervalId = setInterval(this.refreshlist, 1000);
    this.initializeRazorpay();
  }

  componentWillUnmount() {

    clearInterval(this.intervalId);

    if (this.razorscript) {
      document.body.removeChild(this.razorscript);
      this.razorscript = null;
    }

  }

  handleBillingChange = (e) => {
    const { name, value } = e.target;
    const billingAddress = {
      ...this.state.billingAddress,
      [name]: value
    }
    this.setState({ billingAddress })
    if (this.state.sameAddress === "Yes") {
      const shippingAddress = {
        ...billingAddress,
      }
      this.setState({ shippingAddress })
    }
  }

 
  handleCheckoutSubmit = async (e) => {
    e.preventDefault();
   
    if (!window.Razorpay) return;
    try {
      if (Catalog.Cart.items.length == 0) {
        throw new Error('Failed to create an order');

      }
      const data = {
        billingAddress: this.state.billingAddress,
        shippingAddress: this.state.shippingAddress,
        sameAddress: this.state.sameAddress
      }
      const payment = await PaymentService.checkoutCart(data);


      const order = payment.order;
      const orderdate = payment.order.created_at;

      this.setState({ orderdate });
     
      if (!order || !order.id) {
        throw new Error('Failed to create an order');
      }

      const options = {
        key: 'rzp_test_6psStwFrJ906iD',
        amount: order.amount,
        currency: order.currency,
        order_id: order.id,
        prefill: {
          name: this.state.billingAddress.name,
          email: this.state.billingAddress.email,
          contact: this.state.billingAddress.mobile,
        },
        theme: {
          color: '#F37254',
        },
        handler: this.handlePaymentResponce
      };
      const rzp = new window.Razorpay(options);
      console.log(rzp);
      await rzp.open();

      

    } catch (error) {

    }
  };
  refreshlist = async () => {
    if (!this.state.isloaded && Catalog.isLoad) {
      await Catalog.refreshConfig();
      if (Catalog.Session.isLogined) {
        const billingAddress = {
          name: Catalog.Session.user.first_name + " " + Catalog.Session.user.last_name,
          phone: Catalog.Session.user.mobile,
          email: Catalog.Session.user.email,
          address: "",
          pincode: "",
        }
        this.setState({ billingAddress })
      }
    }
    const isloaded = Catalog.isLoad;

    this.setState({ isloaded })
  }

  handlePaymentResponce = async (response) => {

    console.log(Catalog.Cart.items);

    const orderdate = this.state.orderdate;

    const data = {
      billingAddress: this.state.billingAddress,
      shippingAddress: this.state.shippingAddress,
      sameAddress: this.state.sameAddress,
      name: this.state.billingAddress.name,
      email: this.state.billingAddress.email,
      contact: this.state.billingAddress.mobile,
      order: response,
      cart: Catalog.Cart.items,
      orderdate: orderdate
    }

    console.log(data);

    const mailpayment = await PaymentService.paymentUserMail(data);

    Catalog.resetCart();
    const redirectToSuccess =true;
    await PaymentService.verifyPayment(response);
    this.setState({ redirectToSuccess })
  }

  initializeRazorpay = () => {
    this.razorscript = document.createElement("script");
    this.razorscript.src = "https://checkout.razorpay.com/v1/checkout.js";
    this.razorscript.async = true;
    document.body.appendChild(this.razorscript);
  }


  render() {
    const related = [
       
      ];

      const { cartdetaipage } = this.state;
      

      console.log(cartdetaipage);
      let totalamount = 0;
      for (let idx = 0; idx < Catalog.Cart.items.length; idx++) {
        totalamount += Catalog.Cart.items[idx].count * Catalog.Cart.items[idx].Price;

      }

    return (
      <div>
       

        <div className="cartComponent">
        <h2>Cart</h2>
        <CartCard  cartItems={cartdetaipage}/>

        <div className="price-subtotal">
          
          <p>
            Shipping Charge <span>₹100</span>
          </p>
          <hr />
        </div>
        <div className="price-total">
          <p>
            Total <span> ₹{totalamount + 100}</span>
          </p>
          <Button variant="primary" type="button" onClick={this.handleCheckoutSubmit} >
          Proceed to Checkout
                    </Button>
         
        </div>
      </div>
      </div>
      
    );
  }
}
export default CartPage;
