import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
import { SiteService } from "../services/site";
import { API_ENDPOINT } from "../config/constant";
class FormFileMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...props,
    }; 
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  } 
 
  handleRemove = async (e) => {
    const {value}=e.target; 
    const idx = Number.parseInt(value)
    this.state.handle({
      target:{
        name:this.state.name,
        value:this.state.value.filter((imv,imk)=>idx!==imk),
        gallery:this.state.gallery.filter((imv,imk)=>idx!==imk)
      }
    }) 
  }


  handleFileChange = async (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      let formData = new FormData();
      formData.append("files", files[0]);
      let uploads = await SiteService.uploadMedia(formData);
      if (uploads.length > 0) {
        this.state.handle({
          target:{
            name:this.state.name,
            value:[...this.state.value,uploads[0].id],
            gallery:[...this.state.gallery,{
              fileUrl:API_ENDPOINT+uploads[0].url,
              fileName:uploads[0].name,
              fileSize:uploads[0].size,
              fileMime:uploads[0].mime,
            }]
          }
        }) 
      }
    }
  };
  render() {
    return (
      <div className="input-file multiple-file">
        <Form.Group className="mb-3" controlId={this.state.id}>
          <Form.Label>{this.state.title}</Form.Label>
          <Form.Label>
            <InputGroup className="preview-field" hasValidation> 

              <input
                type="file"
                id={this.state.id}
                onChange={this.handleFileChange}
                style={{ display: "none" }} 
                accept={this.state.accept}
                multiple={true}
              />
            </InputGroup>
            
          </Form.Label>
          <div className="upload-group">

            {this.state.gallery && (
                <div className="preview">
                  {this.state.gallery.map((img,i)=>(<div key={i} className="preview-item">
                  <img
                    src={img.fileUrl}
                    alt="Preview"
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                  <button type="button" value={i} onClick={this.handleRemove}>Remove</button>
                  <div  className="upload-detail">
                  {this.state.fileName && (
                    <span>{this.state.fileName}</span>
                  )}
                  {this.state.fileSize && (
                    <span>{this.state.fileSize}KB</span>
                  )}
                  </div>
                  </div>))}
                </div>
              )} 
              {this.state.error&&(<><strong className="text-danger">{this.state.error}</strong></>)}
            </div>
        </Form.Group>
      </div>
    );
  }
}
export default FormFileMultiple;
