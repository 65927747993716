import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class FormSearchDropdown extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
      isShow:false
    }; 
    this.toggleDropclick = this.toggleDropclick.bind(this);
  }
  static getDerivedStateFromProps(props, state) { 
    return {
      ...props,
    };
  }
  toggleDropclick(){
    const isShow=!this.state.isShow;
    this.setState({isShow})
  }
  render() {
    let placeholder=this.state.placeholder;
    if(this.state.value&&this.state.value.length>0){
      placeholder=`${this.state.value.length} item selected`;
    } 

    return (
      <div className="input-field search-field">
        <Form.Group className="mb-3" controlId={this.state.id}>
          <Form.Label>{this.state.title}</Form.Label>  
          <InputGroup hasValidation className="search-field-input">
                <Form.Control type={"text"} isInvalid={this.state.error?true:false} onClick={this.toggleDropclick}   placeholder={placeholder} readOnly={true} />
                <Form.Control.Feedback type="invalid">
                    {this.state.error}
                </Form.Control.Feedback>
          </InputGroup>
          <InputGroup hasValidation className="search-field-dropdown" hidden={!this.state.isShow}>
            {this.state.options.map((option, index) => (
              <Form.Check
                key={index}
                id={this.state.id+""+option.value}
                type="checkbox"
                label={<p>{option.label}</p>}
                name={this.state.name}
                value={option.value}
                checked={this.state.value.includes(option.value.toString())}
                onChange={this.state.handle}  
                isInvalid={this.state.error?true:false}
              />
            ))} 
          </InputGroup>
        </Form.Group>
      </div>
    );
  }
}
export default FormSearchDropdown;
