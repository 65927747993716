import React, { Component, createRef } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
import { SiteService } from "../services/site";
import { API_ENDPOINT } from "../config/constant";
class FormFile extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...props,
    };
    this.fileInputRef = createRef();
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  // handleChange = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) { 
  //     const fileURL = URL.createObjectURL(selectedFile);
  //     this.setState({ fileURL });
  //   }
  // };

  handleClick = async (e) => {
    if(this.fileInputRef.current){
      this.fileInputRef.current.click()
    }
  }

  handleRemove = async (e) => {
    this.state.handle({
      target:{
        name:this.state.name,
        value:null,
        fileUrl:null,
        fileName:null,
        fileSize:null,
        fileMime:null,
      }
    }) 
  }


  handleFileChange = async (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      let formData = new FormData();
      formData.append("files", files[0]);
      let uploads = await SiteService.uploadMedia(formData);
      if (uploads.length > 0) {
        this.state.handle({
          target:{
            name:this.state.name,
            value:uploads[0].id,
            fileUrl:API_ENDPOINT+uploads[0].url,
            fileName:uploads[0].name,
            fileSize:uploads[0].size,
            fileMime:uploads[0].mime,
          }
        }) 
      }
    }
  };
  render() {
    return (
      <div className="input-file">
        <Form.Group className="mb-3" controlId={this.state.id}>
          <Form.Label>{this.state.title}</Form.Label>
          <Form.Label>
            <InputGroup className="preview-field" hasValidation>
              {/* <Form.Control type={"hidden"} name={this.state.name} value={this.state.value??""} />
                <Form.Control type={"file"} name={this.state.name+"_file"} isInvalid={this.state.error?true:false}  onChange={this.state.handle} placeholder={this.state.placeholder} accept={this.state.accept} />
                <Form.Control.Feedback type="invalid">
                    {this.state.error}
                </Form.Control.Feedback> */}

              <input
                type="file"
                id={this.state.id}
                onChange={this.handleFileChange}
                style={{ display: "none" }}
                ref={this.fileInputRef}
                accept={this.state.accept}
              />
              {this.state.fileUrl && (
                <div>
                  <img
                    src={this.state.fileUrl}
                    alt="Preview"
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                  <button type="button" onClick={this.handleRemove}>Remove</button>
                </div>
              )}
            </InputGroup>
            <div className="upload-group">
              {/* <button className="upload-buttton" type="button" onClick={this.handleClick} >Choose file</button> */}
              <div  className="upload-detail">
              {this.state.fileName && (
                <span>{this.state.fileName}</span>
              )}
              {this.state.fileSize && (
                <span>{this.state.fileSize}KB</span>
              )}
              </div>
              {this.state.error&&(<><strong className="text-danger">{this.state.error}</strong></>)}
            </div>
          </Form.Label>
        </Form.Group>
      </div>
    );
  }
}
export default FormFile;
