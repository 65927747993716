import React, { Component } from "react";
import d1 from "./../images/d1.png";
import Delete from "./../images/Delete.svg";
import "./../css/widget/CartCard.css";
import Catalog from "../Catalog";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { SiteService } from "../services/site";

class CartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      isloaded: false,
      isloading: false,
      isSubmit: false,
      ...props,
    
    
    };
  }

  increment = () => {
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }));
    
  };

  decrement = () => {
    this.setState((prevState) => ({
      count: Math.max(prevState.count - 1, 0), 
    }));
  };
  refreshlist = async () => {
    if (!this.state.isloaded && Catalog.isLoad) {
      await Catalog.refreshConfig();
      if (Catalog.Session.isLogined) {
        const billingAddress = {
          name: Catalog.Session.user.first_name + " " + Catalog.Session.user.last_name,
          phone: Catalog.Session.user.mobile,
          email: Catalog.Session.user.email,
          address: "",
          pincode: "",
        }
        this.setState({ billingAddress })
      }
    }
    const isloaded = Catalog.isLoad;

    this.setState({ isloaded })
  }


  componentDidUpdate(){
    if (!this.state.isloaded && Catalog.isLoad) {
      if(!this.intervalId){
        this.intervalId = setInterval(this.refreshlist, 1000);
       
      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.intervalId = setInterval(this.refreshlist, 1000);
   
  }

  RemovetoCart = async (id) => {
    await Catalog.removeFromCart(id)
  };

  updateCartCount = async (id, count) => {
    if (!this.state.isloading) {
      this.setState({ isloading: true })
      await Catalog.updateCartCount(id, count)
    }
    this.setState({ isloading: false })
  }

  
  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { settings } = this.context;
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      errorData[name] = "The " + name + " field is required";
      if (Object.hasOwnProperty.call(formData, name)) {
        if (formData[name] && formData[name] !== "") {
          errorData[name] = null;
        } else {
          isInValid = true;
        }
      } else {
        isInValid = true;
      }
    }
    let isSuccess = false;
    let isSubmit = isInValid ? false : true;
    let error = null
    this.setState({ errorData, isSubmit, isSuccess, error });
    if (!isInValid) {
      const res = await SiteService.loginUser(formData, settings);
      if (res.user) {
        await Catalog.updateCartUser(res.user)
        Catalog.Session = {
          isLogined: true,
          token: res.jwt,
          user: res.user,
        };
        
        const billingAddress = {
          name: res.user.first_name + " " + res.user.last_name,
          phone: res.user.mobile,
          email: res.user.email,
          address: "",
          pincode: "",
        }
        this.setState({ billingAddress })        
        if (this.state.sameAddress === "Yes") {
          const shippingAddress = {
            ...billingAddress,
          }
          this.setState({ shippingAddress })
        }
      }
      if (res.error) {
        error = res.error.message;
      }
      isSubmit = false;
    }
    this.setState({ errorData, isSubmit, isSuccess, error });
  };

    render() {
        const { cartItems, count } = this.state;
      
        console.log(cartItems, 'jkjkjk');
        
        if (this.state.isloaded === true) {
        return (
          <div>
            {cartItems.map((item) => (
              <div className="cartcard" key={item.id}>
                <div className="Cart-content">
                  <div className="Cart-content-section">
                    <img className="cart-content-main" src={d1} alt="book_img" />
                    <div className="cart-content-left">
                      <h4>{item.Title}</h4>
                      <div className="cart-content-quantity">
                      <Button type="button" className="button-minus" disabled={this.state.isloading} size="sm" onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (item.count > 1) {
                                    this.updateCartCount(item.id, item.count - 1)
                                  }
                                }}>-</Button>
                        <p>{item.count || count}</p>
                        <Button type="button" className="button-plus" disabled={this.state.isloading} size="sm" onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  // if(cartItem.count>1){
                                  this.updateCartCount(item.id, item.count + 1)
                                  // }
                                }}>+</Button>
                      </div>
                    </div>
                    <div className="cart-content-right">
                      <h4>{item.Price}</h4>
                      <div className="cart-right-btn">
                        <div className="c1">
                          <img
                            src={Delete}
                            alt="delete"
                            className="right-btn-img"                           
                          />
                        </div>
                        <div className="c2"  onClick={() => this.RemovetoCart(item.id)} >
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        );
      }
      return <>Loading</>;
    }  
}

export default CartCard;
