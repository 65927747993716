import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class PriceFilter extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
      selectedPriceRanges: [], 
      filteredItems: [],
      title: 'Price',
      name: 'price', 
      value: [],  
      error: null, 
      minPrice: 0,
      maxPrice: props.priceRanges||5000,
    }; 
  }

  static getDerivedStateFromProps(props, state) { 
    return {
      ...props,
    };
  }

  // handleCheckboxChange = (e) => {
  //   const { value } = e.target;
  //   this.setState((prevState) => {
  //     const newSelectedPriceRanges = prevState.value.includes(value)
  //       ? prevState.value.filter((item) => item !== value)
  //       : [...prevState.value, value]; 

  //       if (this.state.onFilterChange) {
  //         this.state.onFilterChange(newSelectedPriceRanges);
  //       }

  //     return {
  //       value: newSelectedPriceRanges,
  //     };
  //   });
    
  // };

  handleCheckboxChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const newSelectedPriceRanges = prevState.value.includes(value)
        ? prevState.value.filter((item) => item !== value)
        : [...prevState.value, value];

      if (this.state.onFilterChange) {
        this.state.onFilterChange(newSelectedPriceRanges);
      }

      return {
        value: newSelectedPriceRanges,
      };
    });
  };

  handleMinChange = (event) => {
    const value = Math.min(event.target.value, this.state.maxPrice - 1);
    this.setState({ minPrice: value }, () => {
      this.updateTrack();
      this.updateSelectedPriceRange();
    });
  };

  handleMaxChange = (event) => {
    const value = Math.max(event.target.value, this.state.minPrice + 1);
    this.setState({ maxPrice: value }, () => {
      this.updateTrack();
      this.updateSelectedPriceRange();
    });
  };

  updateTrack = () => {
    const { minPrice, maxPrice } = this.state;
    const track = document.getElementById("track");
  
    const minPercentage = (minPrice / this.state.priceRanges) * 100; 
    const maxPercentage = (maxPrice / this.state.priceRanges) * 100; 

    track.style.left = `${minPercentage}%`;
    track.style.width = `${maxPercentage - minPercentage}%`;
  };


  updateSelectedPriceRange = () => {
    const { minPrice, maxPrice } = this.state;
    const priceRangeString = `${minPrice}-${maxPrice}`;
    
    if (this.state.onFilterChange) {
    
      this.state.onFilterChange([priceRangeString]);
    }
    
    return {
      value: priceRangeString,
    };
};

  // updateSelectedPriceRange = () => {

  //   const { minPrice, maxPrice } = this.state;

  //   const priceRangeString = `${minPrice}-${maxPrice}`;

  //   const { value } = priceRangeString;

  //   this.setState((prevState) => {
  //     const newSelectedPriceRanges = prevState.value.includes(value)
  //       ? prevState.value.filter((item) => item !== value)
  //       : [...prevState.value, value];

  //     if (this.state.onFilterChange) {
  //       this.state.onFilterChange(newSelectedPriceRanges);
  //     }

  //     return {
  //       value: newSelectedPriceRanges,
  //     };
  //   });
  // };

  componentDidMount() {
    this.updateTrack(); 
  }

  // render() {
  //   const { title, name, value, options, error,priceRanges } = this.state;

  //   return (
  //     <div className="input-field">
  //       <Form.Group className="mb-3" controlId={name}>
  //         <Form.Label>{title}</Form.Label>
  //         <InputGroup hasValidation>
  //           {priceRanges.map((option, index) => (
  //             <Form.Check
  //               key={index}
  //               id={`${name}${option.value}`}
  //               type="checkbox"
  //               label={option.label}
  //               name={name}
  //               value={option.value}
  //               checked={value.includes(option.value)} 
  //               onChange={this.handleCheckboxChange}
  //               isInvalid={error ? true : false}  
  //             />
  //           ))}
  //           {error && (
  //             <Form.Control.Feedback type="invalid">
  //               {error} 
  //             </Form.Control.Feedback>
  //           )}
  //         </InputGroup>
  //       </Form.Group>
  //     </div>
  //   );
  // }

  render() {
    const { minPrice, maxPrice,title,name ,priceRanges} = this.state;

    return (

      <Form.Group className="mb-3" controlId={name}>
      <div className="slidecontainer">
         <Form.Label>{title}</Form.Label>

         <p>
           ₹<span>{minPrice}</span> - ₹<span>{maxPrice}</span>
        </p>

        <div className="slider-container">
          <input
            type="range"
            min="0"
            max={priceRanges}
            value={minPrice}
            className="slider"
            id="minRange"
            onChange={this.handleMinChange}
          />
          <input
            type="range"
            min={minPrice + 1}
            max={priceRanges}
            value={maxPrice}
            className="slider"
            id="maxRange"
            onChange={this.handleMaxChange}
          />
          <div className="slider-track" id="track"></div>
        </div>
      
      </div>
      </Form.Group>
    );
  }
    

}

export default PriceFilter;