import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { dateFormat } from "../config/common";

import d1 from "./../images/d1.png";

import "./../css/widget/SearchCard.css";
class DetailCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      active: props.active,
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      active: props.active,
    };
  }
  render() {

    const data=this.state;

    return (
        
          <div className="product-card">
            <img src={data.img} alt="Book Cover" />
            <div className="product-info">
              <h2 className="product-title">{data.name}</h2>
              <p className="product-author">{data.Author}</p>
              <p className="product-description">{data.description}</p>
              <div className="product-price">
                <span className="product-price-current">{data.np}</span>
                <span className="product-price-original">{data.Op}</span>
              </div>
              <div className="product-actions">
                <button className="product-button btn1">Buy Now</button>
                <button className="product-button btn2">Add to Cart</button>
              </div>
            </div>
          </div>
        
      );
  }
}
export default DetailCard;
