import React, { Component } from "react";
import {  InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class FormTextArea extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) { 
      return {
        ...props,
      };
  }
  render() {
    return (        
        <div className="input-field">
          <Form.Group className="mb-3" controlId={this.state.id}>
              <Form.Label>{this.state.title}</Form.Label>
              <InputGroup hasValidation>
                <Form.Control as="textarea" name={this.state.name} isInvalid={this.state.error?true:false} value={this.state.value??""} onChange={this.state.handle} placeholder={this.state.placeholder} readOnly={this.state.readOnly}/>
                <Form.Control.Feedback type="invalid" >
                    {this.state.error}
                </Form.Control.Feedback>
              </InputGroup>
          </Form.Group> 
        </div>
    );
  }
}
export default FormTextArea;