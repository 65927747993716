import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card'; 

import "./../css/widget/TrendingNewsSlider.css";

import { NewsService } from "./../services/news";

class TrendingNewsSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [], 
        };
    } 
    componentDidMount() {
        this.fetchRecentPost();
    }
    fetchRecentPost = async () => {
      const news = await NewsService.fetchTrendingPost();
      this.setState({ news }); 
    };
    render() {
        return (
            <>
                <Card className="news-block">
                    <Card.Body>
                        <div className={"trend-news"}  >
                            <span className="hd">TRENDING NOW</span>
                            <div className="marquee">
                                <div className="marquee-wrap">
                                    {this.state.news ?
                                        this.state.news.map((item) => {
                                            return (
                                                <Link className="trend-txt" to={"/" + item.slug} key={"news-block-" + item.id}>

                                                    <div className="trending">

                                                        <span>{item.title}</span>

                                                    </div>

                                                </Link>
                                            );
                                        }) : (
                                            <></>
                                        )}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card >
            </>

        )
    }
}

export default TrendingNewsSlider;